import * as React from 'react'
import { Link } from 'gatsby'
import SEO from '../components/SEO'

export default function NotFoundPage() {
  return (
    <>
      <SEO title="No se encuentra" />
      <main className="flex">
        <p className="w-11/12 mx-auto mt-10 md:w-1/2 lg:w-1/3 border-pxblue-600 border p-4 bg-gray-100 rounded-md text-pxtx-200">
          No se encuentra la página <br />
          <Link to="/">Ir al inicio</Link>.
        </p>
      </main>
    </>
  )
}
